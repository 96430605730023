<div class="page-wraper">

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- GALLERY SECTION START -->
        <div class="section-full p-t40 p-b50 bg-white">
            <div class="container">
                <!-- PAGINATION START -->
                <div class="filter-wrap work-grid p-b30 text-center">
                    <ul class="masonry-filter link-style ">
                        <li #toate class="active"><a data-filter="*" href="javascript:void(0);"
                                (click)="replaceUrl('/proiecte')">TOATE PROIECTELE</a>
                        </li>
                        <li #civile><a data-filter=".cat-1" href="javascript:void(0);"
                                (click)="replaceUrl('/proiecte/categorie/civil-rezidential')">Civil-Rezidențial</a>
                        </li>
                        <li #industriale><a data-filter=".cat-2" href="javascript:void(0);"
                                (click)="replaceUrl('/proiecte/categorie/industrial')">Industrial</a>
                        </li>
                        <li #comerciale><a data-filter=".cat-3" href="javascript:void(0);"
                                (click)="replaceUrl('/proiecte/categorie/comercial')">Comercial</a>
                        </li>
                        <li #eficienta><a data-filter=".cat-4" href="javascript:void(0);"
                                (click)="replaceUrl('/proiecte/categorie/eficienta-energetica')">Eficiență
                                energetică</a>
                        </li>
                    </ul>
                </div>
                <!-- PAGINATION END -->

                <!-- GALLERY CONTENT START -->
                <div class="masonry-wrap mfp-gallery  row clearfix d-flex justify-content-center flex-wrap">
                    <!-- COLUMNS 1 -->
                    <div *ngFor="let object of projects; index as i;"
                        class="masonry-item {{object.category}} col-lg-4 col-md-6 col-sm-6 m-b30 animate_line"
                        (click)="navigateToProjctDetails(i)">

                        <div class="project-img-effect-1">
                            <img src="{{object.images[0]}}" alt="" />
                            <div class="wt-info">
                                <p class="m-b10 m-t0">{{object.title}}</p>
                                <h4 class="wt-tilte m-b0">{{object.client}}</h4>
                            </div>

                        </div>

                    </div>

                </div>
                <!-- GALLERY CONTENT END -->
            </div>
        </div>
        <!-- GALLERY SECTION END -->

    </div>
    <!-- CONTENT END -->

</div>

<app-loader></app-loader>
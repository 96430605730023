<div class="page-wraper">

    <!-- HEADER START -->
    <app-section-header1></app-section-header1>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- FAQ SECTION START -->
        <div class="section-full p-t20 p-b40 bg-white">
            <div class="container">

                <div class="section-content">

                    <div class="row d-flex justify-content-center">

                        <div class="m-b20 p-a20 d-flex flex-column justify-content-center">
                            <h2 class="m-t0 m-b0 site-text-primary">{{selectedJob.name}}</h2>
                            <!-- <button class="site-button site-btn-effect">Aplică acum</button> -->
                        </div>

                        <div class=" col-lg-8 col-md-6 col-sm-12">

                            <!-- Accordian -->
                            <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">


                                <!-- CRITERII DE SELECTIE -->
                                <div class="panel wt-panel">
                                    <div class="accord-head acc-actives">
                                        <h3 class="acod-title">
                                            <a data-toggle="collapse" data-parent="#accordion5">
                                                Criterii de selecție
                                                <span class="indicator"><i class="fa fa-star"></i></span>
                                            </a>
                                        </h3>
                                    </div>
                                    <div class="acod-body show">
                                        <ul class="acod-content p-l40 p-r40 p-t5 p-b20 bg-gray">
                                            <li *ngIf="!isCriteriiSelectieArray">{{ selectedJob.criterii_selectie }}</li>
                                            <ng-container *ngIf="isCriteriiSelectieArray">
                                              <li *ngFor="let item of selectedJob.criterii_selectie">{{ item }}</li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>

                                <!-- CANDIDATUL IDEAL -->
                                <div class="panel wt-panel">
                                    <div class="accord-head acc-actives">
                                        <h3 class="acod-title">
                                            <a data-toggle="collapse" data-parent="#accordion5">
                                                Candidatul ideal
                                                <span class="indicator"><i class="fa fa-star"></i></span>
                                            </a>
                                        </h3>
                                    </div>
                                    <div class="acod-body show">
                                        <ul class="acod-content p-l40 p-r40 p-t5 p-b20 bg-gray">
                                            <li *ngFor="let row of selectedJob.canditatul_ideal">
                                                {{row}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <!-- DESCRIEREA JOBULUI -->
                                <div class="panel wt-panel">
                                    <div class="accord-head acc-actives">
                                        <h3 class="acod-title">
                                            <a data-toggle="collapse" data-parent="#accordion5">
                                                Descrierea jobului
                                                <span class="indicator"><i class="fa fa-star"></i></span>
                                            </a>
                                        </h3>
                                    </div>
                                    <div class="acod-body show">
                                        <ul class="acod-content p-l40 p-r40 p-t5 p-b20 bg-gray">
                                            <li *ngFor="let row of selectedJob.descrierea_jobului">
                                                {{row}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <!-- SE CERE -->
                                <div class="panel wt-panel">
                                    <div class="accord-head acc-actives">
                                        <h3 class="acod-title">
                                            <a data-toggle="collapse" data-parent="#accordion5">
                                                Se cere
                                                <span class="indicator"><i class="fa fa-star"></i></span>
                                            </a>
                                        </h3>
                                    </div>
                                    <div class="acod-body show">
                                        <ul class="acod-content p-l40 p-r40 p-t5 p-b20 bg-gray">
                                            <li *ngFor="let row of selectedJob.se_cere">
                                                {{row}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <!-- SE OFERA -->
                                <div class="panel wt-panel">
                                    <div class="accord-head acc-actives">
                                        <h3 class="acod-title">
                                            <a data-toggle="collapse" data-parent="#accordion5">
                                                Se oferă
                                                <span class="indicator"><i class="fa fa-star"></i></span>
                                            </a>
                                        </h3>
                                    </div>
                                    <div class="acod-body show">
                                        <ul class="acod-content p-l40 p-r40 p-t5 p-b20 bg-gray">
                                            <li *ngFor="let row of selectedJob.se_ofera">
                                                {{row}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-12">

                            <div class="faq-sidebar">
                                <div class="wt-box p-a30 need-help site-bg-primary text-white m-b30">
                                    <h3 class="m-t0">Ai întrebări?</h3>
                                    <p>Contactează-ne: </p>
                                    <ul class="m-l30">
                                        <li>Email: <strong class="prima"> officesklsolution@gmail.ro</strong></li>
                                        <li>Email: <strong class="prima"> office@skl.ro</strong></li>
                                        <li>Tel: <strong> 0723 000 585</strong></li>
                                        <li>Tel: <strong> 0740 010 252</strong></li>
                                        <li>Tel: <strong> 0745 777 585</strong></li>
                                    </ul>
                                </div>
                            </div>

                        </div>


                    </div>

                </div>

            </div>
        </div>
        <!-- FAQ SECTION END -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>

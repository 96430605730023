<div class="page-wraper">
  <!-- HEADER START -->
  <app-section-header1></app-section-header1>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <app-section-inner-banner [data]="banner"></app-section-inner-banner>
    <!-- INNER PAGE BANNER END -->

    <!-- FAQ SECTION START -->

    <div class="section-full p-t20 p-b40 bg-white">
      <div class="container">
        <div class="section-content">
          <div class="container">
            <div class="row d-flex justify-content-center flex-wrap">
              <div class="col-lg-12 col-md-12 m-b30">
                <p><b>MONITORIZARE ȘI MENTENANTA PREDICTIVA – CORECTIVĂ</b></p>
                <p>
                  In cadrul contractului serviciilor de mentenanță vor fi prevazute intervenții preventive cu
                  periodicitate în mai multe etape: la 6 luni, la 4 luni si la 3 luni de verificare pe an, funcție de
                  varianta optată de beneficiar, având la baza lucrării din categoriile de lucrării specificate în
                  propunerea tehnico-economică.
                </p>
                <p>
                  Dupa fiecare intervenție preventivă, Executantul va transmite catre Beneficiar raportul cu privire la
                  starea sistemelor și rapoartele de verificare efectuate la fața locului semnate și avizate atât de
                  catre reprezentanții Beneficiarului cât și de către reprezentanții Executantului. Rapoartele de
                  intervenție pot fi însotite de devize-oferte, acestea reprezentând lucrări suplimentare, de remediere
                  sau înlocuirea elementelor defecte.
                </p>
                <p>
                  Mențenanța corectivă se va desfașura la cererea Beneficiarului astfel: In momentul apariției unui
                  eveniment Beneficiarul are obligativitatea de a anunta Executantul în scris sau telefonic cu privire
                  la defecțiunea aparuta și de a cere remediere acesteia. Remedierea defecțiunii se va finaliza prin
                  semnarea procesului verbal de interventie corectiva care va conține urmatoarele detalii:
                </p>
                <ul class="list-check-circle primary">
                  <li>constatare și cauză defect;</li>
                  <li>starea sistemului (remediat/ defect);</li>
                  <li>
                    recomandările executantului (ex. înlocuire piesă defectă);
                  </li>
                  <li>dată și durată intervenție;</li>
                  <li>
                    deviz-ofertă (fisa de intervenție și devizul de materiale consummate) privind remedierea defectului
                    constatat (daca este cazul);
                  </li>
                </ul>
                <h3>Monitorizare și mentenanță sisteme fotovoltaice</h3>
                <p>
                  Mentenanța sistemelor fotovoltaice este o condiție implicită a funcționării acestora.
                </p>
                <p>
                  Suntem un integrator de sisteme solare ai cărui ingineri și electricieni sunt calificați în mod
                  special pentru a efectua lucrările necesare în stațiile sistemelor fotovoltaice.
                </p>
                <p>
                  Intreținerea invertoarelor este o activitate de mentenanță a oricarui sistem fotovoltaic. Service-ul
                  acționează asupra întrerupătoarelor, siguranțelor de circuit, riscurilor de supratensiune ori asupra
                  sistemului de ventilație, conform manualului de întreținere a fiecarui echipament în parte.
                </p>
                <p>
                  Suntem o companie EPC (Engineering, Procurement and Construction). Dupa construirea unui sistem
                  fotovoltaic, monitorizam software-ul invertorului in mod constant, datorită faptului ca noi menținem o
                  stransă legatură cu producatorul acestuia.
                </p>
                <p>
                  Anual, inspectăm sisteme solare din punct de vedere al siguranței, respectând reglementări de incendiu
                  și alți factori. Împământarea fundamentală, protecția anti-fulger și protecția fața de scurtcircuit
                  vor fi analizate integral, concluziile rezultate incluzându-se într-un raport predat dumneavoastră.
                </p>
                <p>
                  Toate acestea, precum și alte informații, se includ în rapoarte lunare care dumneavoastră vă vor fi
                  utile la ținerea evidenței privind costurile, producția, livrarea către rețeaua națională, veniturile
                  și, bineînțeles, profitul. Facem acestea pentru dumneavoastră pentru a dovedi că alegerea de sisteme
                  fotovoltaice este o alegere buna. Energia alternativă este cea care merită tot efortul nostru și cea
                  pe care dumneavoastră o veți aprecia.
                </p>
                <p>
                  <b>De ce sistemele fotovoltaice au nevoie de mentenanță?</b>
                </p>
                <p>
                  Dincolo de faptul ca însăși fiabilitatea sistemului fotovoltaic este obținuta prin O&M (operare și
                  mentenantă), aveți nevoie de întreținere de acest tip deoarece in mod cert doriți să beneficiați la
                  maximum de oportunitațile de producere a energiei electrice cu ajutorul energiei solare (energie
                  regenerabilă).
                </p>
                <p>
                  Administrarea sistemelor de acest tip cade integral în grija noastră, la fel si operarea sistemelor
                  prin faptul că un inginer / technician de întreținere este disponibil in permanență.
                </p>
                <p>
                  Faptul ca avem grijă de investiția dumneavoastră îndeaproape, de parca ar fi chiar investiția noastră,
                  inseamnă că eliminam toți factorii perturbatori și orice risc, chiar și ascuns.
                </p>
                <p>
                  De exemplu, fară o inspecție cu cameră termică nu se pot identifica zonele de panou solar care
                  transformă energia solară în energie electrică mai puțin decât ar fi trebuit. Zonele acestea pot
                  apărea ca urmare a funcționării necorespunzatoare a invertoarelor și a cablurilor aferente. Mentenanța
                  ajută, așadar, la optimizarea performanțelor.
                </p>
                <p>
                  <b>Cum se face si ce include mentenanța sistemelor
                    fotovoltaice?</b>
                </p>
                <p>
                  Aceasta se efectuează pe toate ramurile majore ale sistemului desfasurandu-se pe baza componențelor
                  instalate și a parametrilor tehnici:
                </p>
                <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                  <div class="panel wt-panel">
                    <div class="accord-head acc-actives">
                      <h3 class="acod-title">
                        <a data-toggle="collapse" data-parent="#accordion5">
                          Servicii administrative
                          <span class="indicator"><i class="fa fa-star"></i></span>
                        </a>
                      </h3>
                    </div>
                    <div class="acod-body show">
                      <ul class="acod-content p-l40 p-r40 p-t5 p-b20 bg-gray">
                        <li>
                          Curățarea panourilor: spălarea panourilor fotovoltaice
                          vara, îndepartare zăpadă și gheața iarna;
                        </li>
                        <li>Îndepărtarea vegetației;</li>
                        <li>Întreținerea drumurilor interioare;</li>
                        <li>Verificarea sistemului de drenaj.</li>
                      </ul>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="accord-head acc-actives">
                      <h3 class="acod-title">
                        <a data-toggle="collapse" data-parent="#accordion5">
                          Mentenanță echipamente electrice
                          <span class="indicator"><i class="fa fa-star"></i></span>
                        </a>
                      </h3>
                    </div>
                    <div class="acod-body show">
                      <ul class="acod-content p-l40 p-r40 p-t5 p-b20 bg-gray">
                        <li>
                          Efectuăm mentenanță circuitelor de joasă și medie
                          tensiune.
                        </li>
                        <li>
                          Îți garantăm verificarea panourilor, cablurilor și a
                          altor echipamente electrice, folosind camera de
                          termoviziune.
                        </li>
                        <li>
                          Facem verificarea și raportarea calității energiei
                          debitate de invertor.
                        </li>
                        <li>
                          Efectuăm intervenții de reparare, întreținere și
                          înlocuire componente sau echipamente ( invertor,
                          transformator, protectii)
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="accord-head acc-actives">
                      <h3 class="acod-title">
                        <a data-toggle="collapse" data-parent="#accordion5">
                          Mentenanță sisteme mecanice
                          <span class="indicator"><i class="fa fa-star"></i></span>
                        </a>
                      </h3>
                    </div>
                    <div class="acod-body show">
                      <ul class="acod-content p-l40 p-r40 p-t5 p-b20 bg-gray">
                        <li>
                          Verificăm starea suprafeței panourilor, integritatea
                          structurală a echipamentelor, a structurilor de
                          împrejmuire a locațiilor și sistemelor de
                          închidere-deschidere și prindere a echipamentelor.
                        </li>
                        <li>
                          Intervenim rapid pentru orice reparație, întreținere
                          și înlocuire.
                        </li>
                        <li>
                          Pentru serviciile de spălare panouri fotovoltaice
                          folosim utilaje de ultima generație.
                        </li>
                        <li>
                          Utilajul folosit este bazat pe autopropulsie, astfel
                          că este extrem de versatil și poate răspunde unei game
                          variate de situații.
                        </li>
                        <li>
                          Identificăm în timp util erorile de funcționare,
                          evitându-se pierderile de producție.
                        </li>
                        <li>
                          Te ajutăm să identifici cea mai bună și mai rapidă
                          soluție de remediere.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="accord-head acc-actives">
                      <h3 class="acod-title">
                        <a data-toggle="collapse" data-parent="#accordion5">
                          Monitorizare și raportare
                          <span class="indicator"><i class="fa fa-star"></i></span>
                        </a>
                      </h3>
                    </div>
                    <div class="acod-body show">
                      <ul class="acod-content p-l40 p-r40 p-t5 p-b20 bg-gray">
                        <li>
                          Specialiștii noștri efectuează vizite periodice la
                          locație pentru monitorizarea mentenantei.
                        </li>
                        <li>
                          Monitorizăm producția 24/24 și oferim indici de
                          performanță.
                        </li>
                        <li>Oferim notificări în timp real și follow up.</li>
                        <li>
                          Raportare lunară și/sau anuală către clienți, bănci
                          sau alte părți implicate. Interpretare și stocare date
                          SCADA.
                        </li>
                        <li>
                          Realizăm analiză de buget, producție, pierderi,
                          analiză capacitate și performanță, analiză viteză vânt
                          și valori radiație.
                        </li>
                        <li>Oferim recomandări pentru optimizarea operării.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <h3>Mentenanta sisteme antiincendiu</h3>
                <p>In cadrul mentenanței sistemelor antiincendiu noi acoperim următoarele:</p>
                <ul class="list-check-circle primary">
                  <li>sisteme de detecție și avertizare incendiu,</li>
                  <li>sisteme de desfumarea,</li>
                  <li>instalații de monoxid de carbon.</li>
                </ul>
                <h3>Mentenanță sisteme de securitate și siguranța</h3>
                <p>In cadrul mentenanței sistemelor de securitate sunt incluse:</p>
                <ul class="list-check-circle primary">
                  <li>sisteme de supraveghere video,</li>
                  <li>sisteme detecție efracție,</li>
                  <li>sisteme interfonie & videointerfonie,</li>
                  <li>sisteme control access.</li>
                </ul>
                <h3>Mentenanta generală a instalațiilor electrice</h3>
                <p>In cadrul mentenanței sistemelor de securitate sunt incluse:</p>
                <ul class="list-check-circle primary">
                  <li>rețele și circuite electrice</li>
                  <li>grupuri electrogene</li>
                  <li>tablouri electrice</li>
                  <li>instalații iluminat</li>
                  <li>Sistem UPS</li>
                  <li>Sistem BMS</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- CONTENT END -->

        <!-- FOOTER START -->
        <app-section-footer1></app-section-footer1>
        <!-- FOOTER END -->
      </div>

      <app-loader></app-loader>
    </div>
  </div>
</div>
<div class="page-wraper">
  <!-- HEADER START -->
  <app-section-header1></app-section-header1>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <app-section-inner-banner [data]="banner"></app-section-inner-banner>
    <!-- INNER PAGE BANNER END -->

    <!-- FAQ SECTION START -->
    <div class="section-full p-t20 p-b40 bg-white">
      <div class="container">
        <div class="section-content">
          <div class="container">
            <div class="row d-flex justify-content-center flex-wrap">
              <div class="col-lg-12 col-md-12 m-b30">
                <p>
                  <img width="100%" src="../../../../../assets/images/solar.png" />
                </p>
                <h3>Ce sunt panourile fotovoltaice?</h3>
                <p>
                  Sistemele de panouri fotovoltaice sunt destinate producerii energiei electrice prin conversia luminii
                  (energiei solare) în energie electrică.
                </p>
                <p>
                  <b>
                    Exista două tipuri de sisteme fotovoltaice raportat la modul de racordare la rețea:</b>
                </p>
                <ul class="list-check-circle primary">
                  <li>
                    ON-GRID – conectate la SEN (Sistemul Energetic Național).
                  </li>
                  <li>
                    OFF-GRID – acestea pot fi utilizate ca sisteme autonome pentru alimentarea cu energie electrică a
                    unor consumatori aflați la distanță mare de sistemul național de alimentare cu energie electrică,
                    fiind independent energetic.
                  </li>
                </ul>
                <p>
                  Panourile fotovoltaice pot fi instalate fie pe suprafața unui teren, fie pe acoperișul halelor
                  industriale, complexe comerciale sau alte suprafețe de acoperiș.
                </p>

                <p>
                  <b>
                    Există două tipuri de sisteme fotovoltaice raportat la locul de instalare:</b>
                </p>
                <p>
                  Cele mai comun întâlnite sunt cele instalate pe locuințe sau pe clădiri comerciale, de obicei pe
                  acoperiș, cu scopul de a produce strict energia necesară pentru clădirile pe care sunt instalate. Pe
                  de altă parte, există sisteme de parcuri fotovoltaice ce aprovizionează cu energie cartiere întregi,
                  care produc exponențial mai multă energie și devin o alternativă reală la rețeaua clasică de energie
                  electrică.
                </p>
                <h3>
                  Care sunt domeniile în care implementăm soluțiile fotovoltaice?
                </h3>
                <p>
                  <b>Sistemele fotovoltaice industriale și comerciale</b> - sunt
                  sisteme pentru companiile din sectorul industrial și
                  comercial, fiind o soluţie de eficiență energetică pentru
                  afaceri care să le permită să își reducă costurile de operare
                  cu o centrală fotovoltaică proprie sau diferite sisteme
                  industriale și comerciale de panouri solare.
                </p>
                <ul class="list-check-circle primary">
                  <li>
                    <b>Halele metalice</b> destinate producției de obicei sunt
                    construcții cu o suprafață mare pe acoperiș, iar asta
                    înseamnă un potențial foarte mare pentru folosirea
                    panourilor fotovoltaice (solare). De regulă costurile de producție sunt destul de mari, prin
                    instalarea unor sisteme fotovoltaice industriale se realizează o economie la energia electrică, mai
                    ales ca există și sprijin financiar de la stat pentru instalare.
                  </li>
                  <li>
                    <b>Depozitele proprii sau halele metalice</b> de depozitare
                    construite pentru închiriere consumă constant electricitate
                    pentru zona de birouri, iluminat exterior și interior,
                    climatizare, etc. Panourile fotovoltaice montate pe
                    acoperișul depozitelor, conform unor studii recent publicate
                    pot reduce cu până la 60-70% din factura cu energia
                    electrică. Constant și fără costuri de mentenanță.
                  </li>
                  <li>
                    <b>Halele agricole</b> (depozitare cereale, garaj utilaje
                    agricole), însă mai ales fermele de pui de carne sau găini
                    ouătoare, fermele de vaci și/sau de porci consumă constant
                    electricitate pentru ventilare, funcționarea de echipamente,
                    iluminat. Țările din vestul Europei de ani buni au redus
                    facturile la energia electrică prin instalarea de panouri
                    fotovoltaice.
                  </li>
                </ul>
                <p>
                  <b>Sisteme fotovoltaice rezidentiale</b> – alimentează-ți casa
                  cu propria energie electrică produsă de un sistem fotovoltaic
                  bine dimensionat și te bucuri de avantajele acestuia, reducând
                  considerabil factura și nivelul dioxidului de carbon din
                  atmosferă.
                </p>
                <p>
                  Devenind prosumator ai avantajul de a consuma prioritar
                  propria energie! Un autoconsum ridicat pe perioada zilei va
                  face din investiția într-un sistem fotovoltaic cea mai bună
                  alegere.
                </p>
                <p>
                  <b>Parcuri fotovoltaice</b> – În România parcurile solare sunt înca o noutate și reprezintă o piață în
                  plină dezvoltare, cu numeroase oportunități. Producția și distribuția de energie verde, sustenabilă,
                  este viitorul în domeniu. Spre exemplu, în California 20% din energie se produce deja prin parcuri
                  solare și ferme eoliene, iar intenția este de a ajunge cât mai rapid la 35%.
                </p>
                <p>
                  Avantajele investiției într-o astfel de tehnologie sunt aproape nelimitate, de la cele evidente,
                  legate de faptul că soarele este o resursă imensă și gratuită de energie.
                </p>
                <img src="assets/images/parc_fotovoltaic.jpg" /> <br /><br />
                <h3>Beneficii ale implementării unui sistem
                  fotovoltaic</h3>
                <p>
                  Prin ceea ce facem dorim ca soarele să fie sursa dumneavoastră
                  principală de energie. Credem în gândirea diferită, în
                  companiile cu viziuni diferite.
                </p>
                <p>
                  Sistemele industriale și comerciale de panouri solare prezintă
                  avantaje precum:
                </p>
                <ul class="list-check-circle primary">
                  <li>Costuri de operare mai mici</li>
                  <li>
                    Independență față de prețurile energiei electrice și
                    furnizorii de energie.
                  </li>
                  <li>
                    Predictibilitate și stabilitate, a preţului energiei
                    electrice pentru următorii ani.
                  </li>
                  <li>
                    Energie electrică mai ieftină din prima zi de funcționare pe
                    întreaga durată de viață a sistemului fotovoltaic.
                  </li>
                  <li>
                    ​FLEXIBILITATE - În funcție de nevoi, sistemul fotovoltaic
                    poate fi completat cu noi componente (panouri fotovoltaice,
                    invertoare, acumulatori).
                  </li>
                  <li>
                    ROI DE LA 4 ANI - Perioada de recuperare a investiției
                    într-un sistem fotovoltaic bine proiectat și instalat este
                    cu atât mai scurtă, cu cât gradul de autoconsum este mai
                    mare.
                  </li>
                  <li>
                    SUSTENABILITATE - Contribui la un mediu curat pentru noi și
                    generațiile viitoare, reducând emisiile de CO2 din
                    atmosferă.
                  </li>
                </ul>
                <h3>
                  Care sunt etapele pentru instalarea unui sistem de panouri
                  fotovoltaice?
                </h3>
                <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                  <div class="panel wt-panel">
                    <div class="accord-head acc-actives">
                      <h3 class="acod-title">
                        <a data-toggle="collapse" data-parent="#accordion5">
                          ANALIZĂ & CONSULTANȚĂ
                          <span class="indicator"><i class="fa fa-star"></i></span>
                        </a>
                      </h3>
                    </div>
                    <div class="acod-body show">
                      <ul class="acod-content p-l40 p-r40 p-t5 p-b20 bg-gray">
                        <li>analizăm necesarul de energie electrică</li>
                        <li>
                          identificăm consumatorii și caracteristicile acestora
                          (consum, ore de funcționare, soluții pentru
                          eficientizarea acestora)
                        </li>
                        <li>
                          identificăm spațiul disponibil și pretabil pentru
                          montarea panourilor fotovoltaice
                        </li>
                        <li>
                          verificăm orientarea și obstacolele din jur ce ar
                          putea umbri panourile
                        </li>
                        <li>
                          identificăm existența rețelei naționale în zonă și
                          capacitatea transformatorului
                        </li>
                        <li>stabilim bugetul alocat sistemului fotovoltaic</li>
                      </ul>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="accord-head acc-actives">
                      <h3 class="acod-title">
                        <a data-toggle="collapse" data-parent="#accordion5">
                          DIMENSIONARE & PROPUNERE
                          <span class="indicator"><i class="fa fa-star"></i></span>
                        </a>
                      </h3>
                    </div>
                    <div class="acod-body show">
                      <ul class="acod-content p-l40 p-r40 p-t5 p-b20 bg-gray">
                        <li>
                          dimensionăm sistemul (kWp) pe baza necesarului de
                          energie, a spațiului pretabil și bugetului
                        </li>
                        <li>stabilim tipul de panouri fotovoltaice</li>
                        <li>stabilim numărul și tipul invertoarelor</li>
                        <li>
                          stabilim tipului de baterii (în cazul sistemelor off
                          grid sau hibrid)
                        </li>
                        <li>
                          stabilim amplasamentului (sol și/ sau acoperiș) și
                          alegerea structurii de prindere
                        </li>
                        <li>înaintarea și acceptarea ofertei</li>
                      </ul>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="accord-head acc-actives">
                      <h3 class="acod-title">
                        <a data-toggle="collapse" data-parent="#accordion5">
                          MONTAJ & MENTENANȚĂ
                          <span class="indicator"><i class="fa fa-star"></i></span>
                        </a>
                      </h3>
                    </div>
                    <div class="acod-body show">
                      <ul class="acod-content p-l40 p-r40 p-t5 p-b20 bg-gray">
                        <li>
                          obținem avizele și autorizațiile de la autorități,
                          pentru sistemul instalat
                        </li>
                        <li>livrăm echipamentele</li>
                        <li>
                          pregătim echipele și utilajele necesare (operăm doar
                          cu angajați, echipamente și utilaje proprii)
                        </li>
                        <li>efectuăm montajul propriu-zis</li>
                        <li>punem în funcțiune sistemul</li>
                        <li>verificăm buna funcționare a sistemului</li>
                        <li>
                          monitorizăm sistemul și intervenim de fiecare dată
                          cand este nevoie
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FAQ SECTION END -->
  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-section-footer1></app-section-footer1>
  <!-- FOOTER END -->
</div>

<app-loader></app-loader>
import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { PhotoRoute } from 'src/app/core/models/photo-route.model';

@Component({
  selector: 'app-gallery-modal',
  templateUrl: './gallery-modal.component.html',
  styleUrls: ['./gallery-modal.component.css'],
})
export class GalleryModalComponent
  extends SimpleModalComponent<any, any>
  implements OnInit
{
  photosRoute: PhotoRoute[];
  mainPhoto: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.mainPhoto = this.photosRoute.find(
      (element) => element.isActive
    ).urlImage;
  }

  onPhoto(item: PhotoRoute): void {
    if (!item.isActive) {
      this.photosRoute.forEach((element) => {
        element.isActive = false;
      });

      item.isActive = true;
      this.mainPhoto = this.photosRoute.find(
        (element) => element.isActive
      ).urlImage;
    }
  }
}

<div class="page-wraper">
  <!-- HEADER START -->
  <app-section-header1></app-section-header1>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <app-section-inner-banner [data]="banner"></app-section-inner-banner>
    <!-- INNER PAGE BANNER END -->

    <!-- FAQ SECTION START -->
    <div class="section-full p-t20 p-b40 bg-white">
      <div class="container">
        <!-- TITLE START-->

        <div class="m-b20 job-title-wrapper container">
          <div class="service-side-btn m-b50 m-t20">
            <!-- <h3 class="wt-title site-text-primary">Descrierea companiei</h3>
                        <p class=" m-b0">Valoarea <span class="site-text-primary">SKL Technion Solution</span> nu se
                            bazează doar pe compețentele tehnice, ci
                            și
                            pe munca în echipă, seriozitate, integritate si implicare. Credem că rezultatul
                            muncii noastre contribuie la îmbunatățirea societății: infrastructura construită
                            și serviciile oferite stau la baza dezvoltării viitoare. Dinamismul și
                            performanța SKL Technion Solution se cladesc în fiecare zi pe integritatea,
                            energia si motivația echipei, a cărei competență ne diferențiază in piața.
                            Fiecare proiect în care suntem implicați contribuie la creșterea nivelului de
                            expertiză și ne face să fim și o veritabilă Scoală de Inginerie.</p> -->

            <p>
              <b>
                Pentru a oferi servicii calitative clienților și a veni cu un
                plus de valoare pe o piață concurențială, cooptăm în echipă doar
                profesioniști. Ne dorim o îmbunătățire continuă, motiv pentru
                care punem un accent puternic pe instruirea și perfecționarea
                continuă a personalului tehnic. Până la urmă, o parte
                semnificativă a procesului de formare profesională are loc chiar
                la locul de muncă. Angajații noștri sunt o parte din baza
                succesului nostru. Ca să faci parte din echipa
                <span class="site-text-primary">SKL Technion Solution</span>
                trebuie să:</b
              >
            </p>
            <p>
              <b>Fii integru și respectați angajamentele</b> – Facem totul și
              clădim relații și colaborări în care integritatea personalului
              nostru este fundamentul lor. Facem tot posibilul să respectăm cu
              rigurozitate angajamentele luate. Ne-am consolidat poziția și
              reputația pe piață prin lucrările durabile și livrate la timp.
            </p>
            <p>
              <b>Fii pasionat și energic</b> – Chiar dacă activăm într-un
              domeniu tehnic, facem lucrurile cu pasiune și insuflețiți de
              energie. Ne dedicam complet în fiecare proiect derulat și ne
              concentrăm pe oferirea unor servicii excelente.
            </p>
            <p>
              <b>Fii inteligent și profesionist</b> – Inteligența și
              profesionalismul este o cerință esentială, pe care o pretindem de
              la fiecare angajat, actual sau viitor. Echipa noastră deține
              competențe tehnice pe care le folosim în derularea proiectelor
              noastre.
            </p>
            <p>
              <b>Ai abilități de relaționare</b> – O mare parte din activitatea
              noastră se bazează pe munca în echipă. Credem ca fiecare din noi
              are valoare, ne sprijinim, ne respectam reciproc și construim
              relații ce se extind dincolo de mediul profesional.
            </p>
            <p>
              Dacă dorești să te alături echipei SKL Technion Solution, te rugăm 
              să consulți lista posturilor disponibile și să completezi formularul 
              de aplicare..
            </p>
          </div>

          <ng-container *ngFor="let job of jobs; index as i">
            <div
              class="row justify-content-center job-card"
              *ngIf="job.isActive"
            >
              <div class="job-details col-md m-tb20 text-md-left text-center">
                <div class="job-name">{{ job.name }}</div>
                <div class="job-location">Cluj-Napoca</div>
              </div>
              <!-- <a class="contact-slide-show">Detalii <i class="fa fa-angle-right"></i></a> -->
              <a
                routerLink="/cariera/{{ i }}"
                class="site-button-secondry site-btn-effect text-center col-md-2 mr-md-4"
              >
                Detalii >
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-section-footer1></app-section-footer1>
  <!-- FOOTER END -->
</div>

<app-loader></app-loader>

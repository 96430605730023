<div class="section-full p-t40 p-b40 bg-no-repeat bg-bottom-right bg-cover">
  <div class="container">
    <div class="service-side-btn">
      <p>
        <b>Serviciile noastre acoperă o gamă largă de competențe precum cele de
          mai jos, astfel ducând proiectul de la concept la implementare și
          realitate.
        </b>
      </p>
    </div>
    <div class="service-side-btn m-b40">
      <h3 class="competente wt-title site-text-primary">Competențe</h3>
      <ul class="competente-list">
        <li>Management de proiect;</li>
        <li>Antreprenoriat general;</li>
        <li>Întocmire de studii de soluție și fezabilitate;</li>
        <li>Proiecte tehnice;</li>
        <li>Obținere de autorizații în vederea implementării;</li>
        <li>Implementare proiecte la cheie;</li>
        <li>Întocmirea documentației tehnice;</li>
        <li>Proiectare și execuție proiecte la cheie;</li>
        <li>Aplicații BMS și tehnologii SMART;</li>
        <li>Măsuratori diverse;</li>
        <li>Monitorizare și mentenantă predictivă-corectivă;</li>
        <li>
          Lucrari de construcții conexe instalațiilor: fundații, armături, sudură, montaj, ansamblări, uzinaj;
        </li>
        <li>Tablotierat;</li>
        <li>Integrare sisteme</li>
      </ul>
    </div>

    <div class="section-content">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12 m-b50" *ngFor="let service of services; index as i">
          <div class="service-card service-border-box" (click)="navigate(service.path)">
            <div class="wt-box service-box-1 bg-white">
              <div class="service-box-1__top">
                <div class="service-box-title title-style-2 site-text-primary">
                  <span class="s-title-one">{{ service.title }}</span>
                </div>

                <p [innerText]="">{{ service.description }}</p>
                <ul>
                  <li class="m-l30" *ngFor="let item of service.list">
                    {{ item }}
                  </li>
                </ul>
              </div>

              <div class="service-box-1__bottom">
                <div class="service-box-content">
                  <!-- DETAILS LINK -->
                  <div class="service-box-new-link">
                    <a [routerLink]="service.path" class="site-button-link site-text-primary underlined">Detalii</a>
                  </div>
                </div>

                <div class="wt-icon-box-wraper">
                  <div class="wt-icon-box-md site-bg-primary">
                    <span class="icon-cell service-icon"><img src="{{ service.icon }}" alt="" /></span>
                  </div>
                  <div class="wt-icon-number">
                    <span>{{ i + 1 | number : "2.0" }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>